import '../styles/main.scss'
import Swiper, { SwiperPluginPagination } from 'tiny-swiper'

const { fabric } = require('fabric')
const scroll = require('scroll')
const page = require('scroll-doc')()

if (module.hot) module.hot.accept()

document.addEventListener('DOMContentLoaded', function DOMContentLoaded() {
  // scroll to content on nav item click
  const headerNavButtons = [...document.getElementsByClassName('header__navButton')]

  headerNavButtons.map(button => {
    button.addEventListener('click', e => {
      const target = document.getElementById(e.target.dataset.name)
      const targetTop = target.getBoundingClientRect().top - 100

      scroll.top(page, targetTop)
    })
    return true
  })

  // Blobs V2 using fabric.js
  class BlobFabric {
    constructor() {
      this.canvas = new fabric.Canvas('bodyBackground', { selection: true })
      const canvasObj = document.getElementById('bodyBackground')
      this.hostUrl = `https://${canvasObj.dataset.host}`

      fabric.Object.prototype.originX = 'center'
      fabric.Object.prototype.originY = 'center'
      this.setWindowDesignRatio()
      this.setCanvasSize()
      this.loadBlobs()
      this.render()
      this.setEventListeners()
    }

    setWindowDesignRatio = () => {
      this.windowDesignRatio = (1 / 2000) * window.innerWidth
    }

    setEventListeners = () => {
      window.addEventListener('resize', this.onResize)
    }

    onResize = () => {
      this.setWindowDesignRatio()
      this.setCanvasSize()
      this.canvas.getObjects().forEach(img => {
        this.setBlobPositionSize(img)
      })
    }

    setCanvasSize = () => {
      this.canvas.setHeight(window.innerHeight * 2.2)
      this.canvas.setWidth(window.innerWidth)
    }

    setBlobPositionSize = img => {
      img
        .set('left', img.designLeft * this.windowDesignRatio)
        .set('top', img.designTop * this.windowDesignRatio)
        .set('scaleX', this.windowDesignRatio)
        .set('scaleY', this.windowDesignRatio)
        .set('initialScale', this.windowDesignRatio)
        .set('scalingUp', Math.floor(Math.random()))
        .set('scaleSpeed', (Math.random() + 0.5) / 10000)
        .set('moveSpeed', ((Math.floor(Math.random() * 4) + 8) * 3) / 1000)
      return img
    }

    loadBlobs = () => {
      fabric.Image.fromURL(`${this.hostUrl}/wp-content/uploads/blobs/Blob_1.svg`, img => {
        img
          .set('originX', 'left')
          .set('originY', 'top')
          .set('name', 'leftTop')
          .set('designLeft', -70)
          .set('designTop', -300)
        const img2 = this.setBlobPositionSize(img)
        this.canvas.add(img2)
      })

      fabric.Image.fromURL(`${this.hostUrl}/wp-content/uploads/blobs/Blob_2.svg`, img => {
        img
          .set('originX', 'left')
          .set('originY', 'top')
          .set('name', 'leftCenter')
          .set('designLeft', 160)
          .set('designTop', 707)
        const img2 = this.setBlobPositionSize(img)
        this.canvas.add(img2)
      })

      fabric.Image.fromURL(`${this.hostUrl}/wp-content/uploads/blobs/Blob_3.svg`, img => {
        img
          .set('originX', 'left')
          .set('originY', 'top')
          .set('name', 'leftBottom')
          .set('designLeft', -306)
          .set('designTop', 1320)
        const img2 = this.setBlobPositionSize(img)
        this.canvas.add(img2)
      })

      fabric.Image.fromURL(`${this.hostUrl}/wp-content/uploads/blobs/Blob_4.svg`, img => {
        img
          .set('originX', 'left')
          .set('originY', 'top')
          .set('name', 'rightTop')
          .set('designLeft', 1580)
          .set('designTop', -173)
        const img2 = this.setBlobPositionSize(img)
        this.canvas.add(img2)
      })

      fabric.Image.fromURL(`${this.hostUrl}/wp-content/uploads/blobs/Blob_5.svg`, img => {
        img
          .set('originX', 'left')
          .set('originY', 'top')
          .set('name', 'rightCenter')
          .set('designLeft', 1540)
          .set('designTop', 155)
        const img2 = this.setBlobPositionSize(img)
        this.canvas.add(img2)
      })

      fabric.Image.fromURL(`${this.hostUrl}/wp-content/uploads/blobs/Blob_6.svg`, img => {
        img
          .set('originX', 'left')
          .set('originY', 'top')
          .set('name', 'rightBottom')
          .set('designLeft', 1065)
          .set('designTop', 427)
        const img2 = this.setBlobPositionSize(img)
        this.canvas.add(img2)
      })
    }

    animate = () => {
      this.canvas.getObjects().forEach(blob => {
        const obj = blob
        if (obj.scaleX > obj.initialScale + 0.1) {
          obj.scaleY -= obj.scaleSpeed
          obj.scaleX -= obj.scaleSpeed
          obj.scalingUp = !obj.scalingUp
        } else if (obj.scaleX < obj.initialScale - 0.1) {
          obj.scaleY += obj.scaleSpeed
          obj.scaleX += obj.scaleSpeed
          obj.scalingUp = !obj.scalingUp
        } else if (obj.scalingUp) {
          obj.scaleY += obj.scaleSpeed
          obj.scaleX += obj.scaleSpeed
          obj.top += obj.moveSpeed
          obj.left += obj.moveSpeed
        } else {
          obj.scaleY -= obj.scaleSpeed
          obj.scaleX -= obj.scaleSpeed
          obj.top -= obj.moveSpeed
          obj.left -= obj.moveSpeed
        }
      })
      this.render()
    }

    render = () => {
      this.canvas.renderAll()
      fabric.util.requestAnimFrame(this.animate)
    }
  }

  /* eslint-disable no-unused-vars */
  const blobs = new BlobFabric()
  /* eslint-enable no-unused-vars */

  const galleries = [...document.getElementsByClassName('gallery')]
  galleries.forEach(gallery => {
    /* eslint-disable no-unused-vars */
    const swiperGallery = new Swiper(gallery, {
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        bulletClass: 'gallery__bullet',
        bulletActiveClass: 'gallery__bullet--active'
      },
      plugins: [SwiperPluginPagination]
    })
    /* eslint-enable no-unused-vars */

    swiperGallery.on('after-slide', () => {
      /* eslint-disable no-undef */
      ga('send', 'event', 'Gallery', 'slide present', 'touch', gallery)
      /* eslint-enable no-undef */
    })

    window.addEventListener('resize', () => {
      swiperGallery.update()
    })
  })
})
